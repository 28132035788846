import React from 'react';

import type { BreakpointProps } from '@glass/web/components/Carousel/responsive';
import { BreakpointTypes, XS } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles<{
  breakpointProps: BreakpointProps;
}>()((theme, { breakpointProps }) => {
  // Compute responsiveClasses for the children slides for each breakpoint based on the breakpointProps
  const slidesResponsiveClasses = Object.keys(breakpointProps).reduce(
    (acc, breakpointKey) => {
      if (breakpointKey === 'default') return acc;

      const visibleSlides =
        breakpointProps[breakpointKey as keyof BreakpointProps]?.visibleSlides || 1;
      return {
        ...acc,
        [theme.breakpoints.only(breakpointKey as BreakpointTypes)]: {
          // Hide all the other slides
          // todo: remove ":nth-child": "The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type"."
          [`:nth-child(n + ${1 + visibleSlides})`]: {
            display: 'none',
          },
        },
      };
    },
    // Default initialization value
    {
      [theme.breakpoints.up(XS)]: {
        // todo: remove ":nth-child": "The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type"."
        [`:nth-child(n + ${1 + breakpointProps.default.visibleSlides})`]: {
          display: 'none',
        },
      },
    },
  );

  return {
    root: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      alignItems: 'center',
      display: 'flex',
      '@media (pointer: coarse)': {
        padding: 0,
      },
    },
    slidesList: {
      height: '100%',
      width: '100%',
      display: 'flex',
      // Responsive styles for each child slide
      '> *': {
        ...slidesResponsiveClasses,
      },
    },
  };
});

function SlidesSSRWrapper({
  children,
  breakpointProps,
}: {
  children?: React.JSX.Element[];
  breakpointProps: BreakpointProps;
}) {
  const { classes } = useStyles({
    breakpointProps,
  });
  // This simulates the Nuka carousel slide container
  return (
    <div className={classes.root}>
      <div className={classes.slidesList}>{children}</div>
    </div>
  );
}

export default React.memo(SlidesSSRWrapper);
